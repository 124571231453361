import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CustomLayoutComponent} from './custom-layout/custom-layout.component';
import {VexRoutes} from '../@vex/interfaces/vex-route.interface';
import {QuicklinkModule, QuicklinkStrategy} from 'ngx-quicklink';
import {AuthGuard} from "./pages/pages/auth/guards/auth.guard";

const routes: VexRoutes = [
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/main',
        pathMatch: 'full'
      },
      {
        path: 'main',
        loadChildren: () => import('./pages/main-page/main-page.module').then(m => m.MainPageModule),
      },
      {
        path: 'service',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/service-page/service-page.module').then(m => m.ServicePageModule)
      },
      {
        path: 'calculator',
        loadChildren: () => import('./pages/main-page/calculator/calculator.module').then(m => m.CalculatorModule)
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'my-applications',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/my-applications/my-applications.module').then(m => m.MyApplicationsModule)
      },
      {
        path: 'error-404',
        loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      },
      {
        path: 'error-500',
        loadChildren: () => import('./pages/pages/errors/error-500/error-500.module').then(m => m.Error500Module)
      },
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/pages/auth/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: '**',
    loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
  }
  , {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'dashboards/analytics',
        redirectTo: '/'
      },

      {
        path: '',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
      },
    ]
  }
  //     {
  //       path: 'pages',
  //       children: [
  //         {
  //           path: 'pricing',
  //           loadChildren: () => import('./pages/pages/pricing/pricing.module').then(m => m.PricingModule)
  //         },
  //         {
  //           path: 'faq',
  //           loadChildren: () => import('./pages/pages/faq/faq.module').then(m => m.FaqModule)
  //         },
  //         {
  //           path: 'invoice',
  //           loadChildren: () => import('./pages/pages/invoice/invoice.module').then(m => m.InvoiceModule)
  //         },
  //         {
  //           path: 'error-404',
  //           loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
  //         },
  //         {
  //           path: 'error-500',
  //           loadChildren: () => import('./pages/pages/errors/error-500/error-500.module').then(m => m.Error500Module)
  //         }
  //       ]
  //     },
  //     {
  //       path: '**',
  //       loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
  //     }
  //   ]
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {
}
