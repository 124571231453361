import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../../../../../core/service/auth.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'vex-signature-dialog',
  templateUrl: './signature-dialog.component.html',
  styleUrls: ['./signature-dialog.component.scss']
})
export class SignatureDialogComponent implements OnInit {

  webSocket: any;
  redirectLink: string;

  constructor(private dialogRef: MatDialogRef<SignatureDialogComponent>,
              private _snackBar: MatSnackBar,
              private _activateRoute: ActivatedRoute,
              private _httpClient: HttpClient,
              private authService: AuthService,
              private _router: Router,
              private translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.redirectLink = this.data;
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  signIn() {
    this.webSocket = new WebSocket('wss://127.0.0.1:13579/');
    this.webSocket.onopen = (event: any) => {
      // this.connection = true;
        var o = {
          module: "kz.gov.pki.knca.commonUtils",
          method: "getKeyInfo",
          args: ['PKCS12']
        };
      this.webSocket.send(JSON.stringify(o));
    };
    this.webSocket.onclose = (event: any) => {
    };

    this.webSocket.onerror = (event: any) => {
      if (event.type === 'error') {
        this._snackBar.open(this.translate.instant('Error connecting to NCALayer'), 'Ок', {
          duration: 3000,
          panelClass: ["snack-bar"]
        });
      }
    };

    this.webSocket.onmessage = (event: any) => {
      const data = JSON.parse(event.data);
      if (data.responseObject) {


        let signInfo: any = data.responseObject;

        // this.showLoaderAuth = true;
        let iin = data.responseObject.subjectDn.split('SERIALNUMBER=IIN')[1].slice(0, 12);
        let req = {
          iin: iin,
          fio: signInfo.subjectCn,
          sign: signInfo.pem
        }
        if (this.redirectLink != null) {
          this._httpClient.get(this.redirectLink);
        }
        // this.closeDialog();
        this.authService.signInKey(req).subscribe(res => {
          if (res) {
            res.fio = req.fio;
            localStorage.setItem('HEADER_USER', JSON.stringify(res));
            localStorage.setItem('accessToken', res.accessToken);
              this.closeDialog();
              // if (this.redirectLink != null) {
              //   this._httpClient.get(this.redirectLink);
              // }
          }

          const redirectURL = this.redirectLink || '/main';
          // Navigate to the redirect url
          this._router.navigateByUrl(redirectURL);
        });
      }
    };
  }
}
